import React, { useEffect, useState } from 'react';
import { AdminNav } from '../NavBar/AdminNav';
import api from '../api';

export const DealerListing = () => {
    const [dealerlisting, setDealerListing] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [hst, setHst] = useState('');
    const [rin, setRin] = useState('');
    const [currentDealerId, setCurrentDealerId] = useState(null);
    const [dealercount, setDealerCount] = useState(0); // State for seller count
    const handleHstChange = (e) => setHst(e.target.value);
    const handleRinChange = (e) => setRin(e.target.value);

    const handleSave = async () => {
        if (!currentDealerId) return;

        const payload = { hst, rin };
       

        try {
            // Send the POST request to the backend API to update the dealer's data
            const response = await api.post(`/admin/dealerupdate/${currentDealerId}`, payload);
        
            if (response.status === 200) {
                alert("Details updated successfully!");
                setIsEditing(false);  // Close the modal
                await fetchData();    // Reload the dealer data
        
                // Optional: Check if the data is updated in the state
                if (dealerlisting.some(dealer => dealer.Dealer_Id === currentDealerId && dealer.hst === hst && dealer.rin === rin)) {
                    console.log("Data updated successfully in the component.");
                } else {
                    console.log("Reloading page as data might not have refreshed.");
                    window.location.reload(); // Reload the page if data hasn't updated
                }
            } else {
                alert("Failed to update details.");
            }
        } catch (error) {
            console.error("Error updating dealer details:", error);
            alert("An error occurred while updating details.");
        }
    }
    const handleEditClick = (dealer) => {
        setIsEditing(true);
        setCurrentDealerId(dealer.Dealer_Id);
        setHst(dealer.HST); // Ensure correct capitalization here
        setRin(dealer.RIN);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.get('/dealer/dealerlist');
            if (response.data.Dealer && Array.isArray(response.data.Dealer)) {
                console.log(response.data.Dealer)
                setDealerListing(response.data.Dealer);
                const DealerList = response.data.Dealer;
                  setDealerCount(DealerList.length);
            } else {
                console.error('Data is not in the expected format:', response.data);
                setDealerListing([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const toggleActivation = async (dealerId) => {
        try {
            await api.post(`/admin/dealeractivatedeactivate/${dealerId}`);
            fetchData();
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };


    const handleCancel = () => {
        setIsEditing(false);
        setCurrentDealerId(null);
    };

    const Postdataavtivate = async (dealerId, dealer_varify) => {
        try {
            await api.post(`/admin/dealervarificaion/${dealerId}`, { dealer_varify });
            fetchData();
        } catch (error) {
            console.error('Error updating dealer status:', error);
        }
    };
    return (
        <section className="dealer-listing">
            <AdminNav />
            <div className="mid-panel mt-3">
                <div className="container">
                <div class="toplisting">
                <h3 className="main-heading">Dealer Listing</h3>
                    <div class="badge badge-primary p-2">Total Dealers:  <div class="count"><strong>{dealercount}</strong></div></div>
               </div>
                    {loading ? (
                        <p>Loading...</p>
                    ) : dealerlisting.length === 0 ? (
                        <div className="col-md-12">
                            <p className="text-center text-danger">No Dealer Found</p>
                        </div>
                    ) : (
                        <div className="flip-card">
                            <div className="row">
                                {dealerlisting.map((item) => (
                                    <div className="col-lg-4 col-md-6 pt-3" key={item.Dealer_Id}>
                                        <div className="card card-simple">
                                            <div className="card-front text-white badge badge-primary">
                                                <div className="card-body">
                                                    <h6 className="card-title">{item.Dealer_Fname} {item.Dealer_Lname}</h6>
                                                    <h6 className="card-title">{item.Dealer_Address}</h6>
                                                    <h6 className="card-title">{item.Dealership_ID}</h6>
                                                    <h6 className="card-title">{item.Dealer_Phone}</h6>
                                                    <h5 className="card-title">{item.Dealer_Status === 1 ? "Active" : "Inactive"}</h5>
                                                   <div className="text-center"> 
                                                    <button
                                                        className="btn btn-sm btn-outline-light px-2 py-2 mt-2"
                                                        onClick={() => handleEditClick(item)}
                                                        data-toggle="modal"
                                                        data-target="#editModal"
                                                    >
                                                        Edit
                                                    </button>
                                                    </div>
                                                    <div className="text-center">
                                                        {item.Dealer_Signup_Status === 0 && item.Dealer_Status === 1 && (
                                                            <>
                                                                <button
                                                                    className="btn btn-sm btn-outline-light px-2 py-2 mt-2 mr-3"
                                                                    onClick={() => Postdataavtivate(item.Dealer_Id, "1")}
                                                                >
                                                                    Verify
                                                                </button>
                                                                <button
                                                                    className="btn btn-sm btn-outline-light px-2 py-2 mt-2"
                                                                    onClick={() => Postdataavtivate(item.Dealer_Id, "2")}
                                                                >
                                                                    Disallow
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="text-center">
                                                        {item.Dealer_Signup_Status === 1 && (
                                                            item.Dealer_Status === 1 ? (
                                                                <button className="btn btn-sm btn-outline-light px-2 py-2 mt-2" onClick={() => toggleActivation(item.Dealer_Id)}>
                                                                    Deactivate
                                                                </button>
                                                            ) : (
                                                                <button className="btn btn-sm btn-outline-light px-2 py-2 mt-2" onClick={() => toggleActivation(item.Dealer_Id)}>
                                                                    Activate
                                                                </button>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {isEditing && currentDealerId && (
    <div className="modal fade show" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="editModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="editModalTitle">Edit Dealer Details</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCancel}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label>GST</label>
                        <input
                            type="text"
                            className="form-control"
                            value={hst}
                            onChange={handleHstChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>RIN</label>
                        <input
                            type="text"
                            className="form-control"
                            value={rin}
                            onChange={handleRinChange}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleSave}>
                        Save changes
                    </button>
                </div>
            </div>
        </div>
    </div>
)}
        </section>
    );
};
